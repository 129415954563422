import React from 'react';
import Footer from './Footer'; // Import the Footer component

const AboutUs = () => {
    return (
        <div className="w-full flex flex-col font-sans">
            <div className="mt-0 mb-0 p-2"> {/* Padding similar to Home */}
                <div className="w-full max-w-7xl mx-auto bg-dots bg-gray-100 p-4 sm:p-6 md:p-8 rounded-2xl shadow-2xl bordered-frame">
                    <div className="text-center mb-4 px-2 sm:px-4 lg:px-6">
                        <h1 className="text-2xl sm:text-4xl md:text-5xl font-bold w-full mx-auto" style={{ fontFamily: 'Poppins, sans-serif' }}>
                            About <span style={{ color: '#3a5a40' }}>Madison's Menu</span>
                        </h1>

                    </div>

                    {/* Footer */}
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
