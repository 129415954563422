import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import AboutUs from './components/AboutUs';  // Import the About Us page component
import Menu from './components/Menu';        // Import the Menu page component
import supabase from './supabaseClient';
import NavBar from './components/NavBar';

function App() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
            setUser(session?.user || null);
        });
        return () => subscription.unsubscribe();
    }, []);

    return (
        <Router>
            <div className="min-h-screen flex flex-col mt-2">
                <NavBar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<AboutUs />} />  {/* Route for About Us page */}
                    <Route path="/menu" element={<Menu />} />      {/* Route for Menu page */}
                </Routes>
            </div>
        </Router>
    );
}

export default App;
