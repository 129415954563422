import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DayMenu from './DayMenu';
import Footer from './Footer'; // Import the Footer component
import AnimatedNumber from './AnimatedNumber';


const Home = () => {
    const buttonBaseClass = "border border-gray-300 shadow-md rounded-full text-center transition-all duration-300";

    const [goalType, setGoalType] = useState('Tasty');
    const [selectedCuisine, setSelectedCuisine] = useState('Indian'); // Set 'Indian' as default
    const [selectedRestrictions, setSelectedRestrictions] = useState([]);
    const [selectedDifficulty, setSelectedDifficulty] = useState('Cook'); // Set 'Cook' as default
    const [isLoading, setIsLoading] = useState(false);
    const [weeklyMenus, setWeeklyMenus] = useState([]);
    const [error, setError] = useState(null);

    const foodTypes = ['Asian', 'French', 'Halloween', 'Indian', 'Italian', 'Mexican'];
    const dietaryRestrictions = ['Dairy', 'Meat', 'Seafood'];
    const difficulties = ['Novice', 'Cook', 'Chef'];

    const handleGoalTypeChange = (type) => setGoalType(type);
    const handleFoodTypeChange = (foodType) => {
        setSelectedCuisine(selectedCuisine === foodType ? null : foodType);
    };
    const handleRestrictionChange = (restriction) => {
        setSelectedRestrictions((prev) =>
            prev.includes(restriction) ? prev.filter((r) => r !== restriction) : [...prev, restriction]
        );
    };
    const handleDifficultyChange = (difficulty) => {
        setSelectedDifficulty(difficulty);
    };

    const Separator = () => (
        <div className="flex items-center justify-center my-4">
            <div className="bg-gray-300 h-px w-1/3"></div>
        </div>
    );

    const validateMenuData = (data) => {
        if (typeof data !== "object" || !data) return false;
        const requiredKeys = ["breakfast", "lunch", "dinner"];
        return requiredKeys.every(key =>
            data[key] && typeof data[key].title === "string" && typeof data[key].description === "string"
        );
    };

    const apiBaseUrl = process.env.NODE_ENV === 'production'
        ? `https://${window.location.host}/api/openai`
        : 'http://localhost:3001/api/openai';

    const handleGenerateMenu = async () => {
        setIsLoading(true);
        setError(null);

        let prompt = `
You are an expert culinary assistant tasked with creating a detailed meal plan in JSON format for a specific diet. The meal plan should include breakfast, lunch, and dinner. Each meal entry must contain the following:

1. **Title**: The name of the dish.
2. **Description**: A brief description of the dish.
3. **Prep and Cook Time**: Estimated preparation and cooking time, provided in minutes.
4. **Ingredients**: A list of ingredients formatted as bullet points, with quantities suitable for 4 persons. The ingredients should be ranked by logical categories (like Dairy, Vegetables, Spices), but these category labels should not be included in the final output.
5. **Recipe**: A clear step-by-step guide on how to prepare the dish. Each step should be prefixed with "Step X:", where X is the step number. The text should not be in bold and should not include any additional numbering (e.g., no "1. Step 1:"). The format should be exactly "Step X: [instruction]".

The meal plan should be tailored for a ${goalType === 'Weight-loss' ? 'weight-loss' : 'tasty'} diet.
${selectedCuisine ? `The cuisine should be ${selectedCuisine}.` : ''}
${selectedRestrictions.length > 0 ? `The dietary restrictions are: ${selectedRestrictions.join(', ')}.` : ''}
All meals should be gluten-free.
Make it clear in the title of each meal that it's Gluten-Free.
The JSON format should strictly follow this structure:

{
  "breakfast": {
	"title": "Dish name for breakfast",
	"description": "A brief description of the dish.",
	"prep_time": "Estimated prep time in minutes",
	"cook_time": "Estimated cook time in minutes",
	"ingredients": [
	  "2 cups (480 ml) milk",
	  "4 oz (113 g) butter",
	  "1 large onion, diced"
	],
	"recipe": [
	  "Step 1: Do this...",
	  "Step 2: Do that..."
	]
  },
  "lunch": {
	"title": "Dish name for lunch",
	"description": "A brief description of the dish.",
	"prep_time": "Estimated prep time in minutes",
	"cook_time": "Estimated cook time in minutes",
	"ingredients": [
	  "2 cups (480 ml) milk",
	  "4 oz (113 g) butter",
	  "1 large onion, diced"
	],
	"recipe": [
	  "Step 1: Do this...",
	  "Step 2: Do that..."
	]
  },
  "dinner": {
	"title": "Dish name for dinner",
	"description": "A brief description of the dish.",
	"prep_time": "Estimated prep time in minutes",
	"cook_time": "Estimated cook time in minutes",
	"ingredients": [
	  "2 cups (480 ml) milk",
	  "4 oz (113 g) butter",
	  "1 large onion, diced"
	],
	"recipe": [
	  "Step 1: Do this...",
	  "Step 2: Do that..."
	]
  }
}

Please ensure the recipe steps are formatted as "Step X: [instruction]" with no additional text, bold formatting, or numbering. Make sure that the last step makes it clear that all the steps are done and it's ready to eat. Only provide the JSON output with no additional text or explanations.
`;

        prompt += ` The meal plan should be designed for a ${selectedDifficulty || 'Cook'} level of cooking skill.`;

        try {
            const response = await axios.post(
                apiBaseUrl,
                {
                    prompt: prompt.trim(),
                    cookingLevel: selectedDifficulty || 'Cook',
                    dietName: goalType === 'Weight-loss' ? 'Weight-loss' : 'Tasty'
                }
            );

            const chatGptMessage = response.data.content.trim();
            const jsonStartIndex = chatGptMessage.indexOf('{');
            const jsonEndIndex = chatGptMessage.lastIndexOf('}');
            const jsonResponse = chatGptMessage.slice(jsonStartIndex, jsonEndIndex + 1);

            let generatedMenus;
            try {
                const parsedResponse = JSON.parse(jsonResponse);
                generatedMenus = parsedResponse;
            } catch (e) {
                console.error('Failed to parse JSON. Response was:', jsonResponse);
                setError('Failed to parse the generated menu. Please try again.');
                return;
            }

            if (validateMenuData(generatedMenus)) {
                setWeeklyMenus([generatedMenus]);

                 //Save the menu to the database
                 //After generating the menu, store it in the DB
                //await axios.post('/api/store-menu', {
                //    preference: goalType === 'Weight-loss' ? 'Weight-loss' : 'Tasty',
                //    cuisine: selectedCuisine,
                //    restrictions: selectedRestrictions,
                //    difficulty: selectedDifficulty || 'Cook',
                //    menu: generatedMenus
                //});
            } else {
                console.error('Generated menu data is invalid:', generatedMenus);
                setError('Generated menu data is invalid. Please try again.');
            }
        } catch (error) {
            console.error('Error generating menu:', error);

            if (error.response && error.response.status === 429) {
                setError('Thank you for loving Madison\'s Menu so much. You have reached your daily 3 Magic Menus. Please come back tomorrow.');
            } else {
                setError('An error occurred while generating the menu. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const storeMenuInDB = async (preference, cuisine, restrictions, difficulty, menu) => {
        try {
            const response = await axios.post('/api/store-menu', {
                preference,
                cuisine,
                restrictions,
                difficulty,
                menu,
            });
            console.log('Menu stored successfully:', response.data);
        } catch (error) {
            console.error('Error storing menu in the database:', error);
        }
    };

    return (
        <div className="w-full flex flex-col font-sans">
            <div className="mt-0 mb-0 p-2"> {/* Reduced padding */}
                <div className="w-full max-w-7xl mx-auto bg-dots bg-gray-100 p-4 sm:p-6 md:p-8 rounded-2xl shadow-2xl bordered-frame"> {/* Reduced padding */}
                    <div className="text-center mb-4 px-2 sm:px-4 lg:px-6"> {/* Reduced margin */}
                        <h1 className="text-2xl sm:text-4xl md:text-5xl font-bold w-full mx-auto" style={{ fontFamily: 'Poppins, sans-serif' }}>
                            Tasty <span style={{ color: '#3a5a40' }}>Gluten-Free</span> Menus in Seconds
                        </h1>
                        <p className="mt-2 sm:mt-4 text-s sm:text-xl italic" style={{ fontFamily: 'Poppins, sans-serif' }}>
                            Create custom menus designed <span className="font-bold">for you</span>.
                        </p>
                        <AnimatedNumber />

                        {/* Image below the animated number */}
                        {/*<img*/}
                        {/*    src="/mm1.jpg"   Correct path to the image */}
                        {/*    alt="Delicious Gluten-Free Menu"*/}
                        {/*    className="mx-auto mt-4"   Centering the image and adding margin-top */}
                        {/*/>*/}
                    </div>


                   

                    {/* Step 1: Select Your Food Preferences */}
                    <div className="mb-8 sm:mb-12 mt-14">
                        <h2 className="text-xl font-semibold text-center mb-4 latoText">Step 1: Select Your Food Preferences</h2>

                        {/* Goal Type Toggle */}
                        <div className="flex justify-center mb-4">
                            <button
                                className={`px-4 py-2 rounded-l-full text-black shadow-md transition-all duration-300 border border-gray-300 ${goalType === 'Weight-loss' ? 'bg-[#A3B18A] shadow-lg' : 'bg-white hover:bg-gray-100 hover:shadow-lg'}`}
                                onClick={() => handleGoalTypeChange('Weight-loss')}
                            >
                                Weight-loss
                            </button>
                            <button
                                className={`px-4 py-2 rounded-r-full text-black shadow-md transition-all duration-300 border border-gray-300 ${goalType === 'Tasty' ? 'bg-[#A3B18A] shadow-lg' : 'bg-white hover:bg-gray-100 hover:shadow-lg'}`}
                                onClick={() => handleGoalTypeChange('Tasty')}
                            >
                                Tasty
                            </button>
                        </div>


                        <Separator />

                        {/* Food Types (Preferred Cuisines) */}
                        <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8"> {/* Added max-width and centered content */}
                            <p className="text-center mb-4">Select your preferred cuisine:</p>
                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 mb-4">
                                {foodTypes.map((type) => (
                                    <button
                                        key={type}
                                        className={`${buttonBaseClass} h-12 w-full px-2 text-xs sm:text-sm ${selectedCuisine === type ? 'bg-[#A3B18A] border-gray-300' : 'bg-white hover:bg-gray-100 border-gray-300'
                                            }`}
                                        onClick={() => handleFoodTypeChange(type)}
                                    >
                                        <span className="truncate">{type}</span>
                                    </button>
                                ))}
                            </div>
                            <Separator />
                            {/* Dietary Restrictions */}
                            <p className="text-center mb-4">Select any dietary restrictions:</p>
                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                                {dietaryRestrictions.map((restriction) => (
                                    <button
                                        key={restriction}
                                        className={`${buttonBaseClass} h-12 w-full px-2 text-xs sm:text-sm ${selectedRestrictions.includes(restriction) ? 'bg-[#A3B18A] shadow-lg' : 'bg-white hover:bg-gray-100 border-gray-300'
                                            }`}
                                        onClick={() => handleRestrictionChange(restriction)}
                                    >
                                        <span className="truncate">{restriction}</span>
                                    </button>
                                ))}
                            </div>
                        </div>

                    </div>

                    {/* Step 2: Select the Difficulty */}
                    <div className="mb-12 mt-8">
                        <h2 className="text-xl font-semibold text-center mb-4">Step 2: Select the Difficulty</h2>
                        <div className="flex justify-center">
                            {difficulties.map((difficulty, index) => (
                                <button
                                    key={difficulty}
                                    className={`px-4 py-2 shadow-md transition-all duration-300 border border-gray-300 ${index === 0 ? 'rounded-l-full' : index === difficulties.length - 1 ? 'rounded-r-full' : ''} ${selectedDifficulty === difficulty ? 'bg-[#A3B18A] text-black' : 'bg-white text-gray-700 hover:bg-gray-100'}`}
                                    style={selectedDifficulty === difficulty ? { backgroundColor: '#A3B18A', color: 'black' } : {}}
                                    onClick={() => handleDifficultyChange(difficulty)}
                                >
                                    {difficulty}
                                </button>

                            ))}
                        </div>
                    </div>

     
                    <div className="text-center mt-8"> {/* mt-8 added */}
                        {isLoading ? (
                            <div className="space-y-4">
                                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 mx-auto"
                                    style={{ borderColor: '#3A5A40' }}>
                                </div>
                                <p className="text-gray-700 font-semibold italic" style={{ fontFamily: 'inherit' }}>
                                    Your menu will be ready in less than 30 seconds...
                                </p>
                            </div>
                        ) : (
                            <button
                                className="nature-bg text-white font-bold py-4 px-10 rounded-full shadow-lg hover:shadow-xl transition-all duration-300"
                                onClick={handleGenerateMenu}
                                disabled={isLoading}
                            >
                                GENERATE MY MENU
                            </button>
                        )}

                    </div>


                    {error && (
                        <p className="text-center mt-4 text-sm text-red-500">
                            {error}
                        </p>
                    )}

                    {/* Display generated menu */}
                    {weeklyMenus.length > 0 && (
                        <div className="mt-8 space-y-4">
                            {weeklyMenus.map((menu, index) => (
                                <DayMenu key={index} meals={menu} onRegenerate={handleGenerateMenu} />
                            ))}
                        </div>
                    )}
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Home;