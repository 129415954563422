import React, { useState, useEffect } from 'react';

const AnimatedNumber = () => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        let start = 0;
        const end = 1121; // The number we want to count up to
        const duration = 4600;
        const increment = end / (duration / 50); // Update 50 times per second

        const timer = setInterval(() => {
            start += increment;
            if (start >= end) {
                clearInterval(timer);
                setCount(end);
            } else {
                setCount(Math.ceil(start));
            }
        }, 50);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="flex justify-center items-center mt-6 text-gray-600 text-xs">
            <img src="/gluten-free-logo.png" alt="Menu Icon" className="w-6 h-6 mr-2" />
            {count} menus generated
        </div>
    );
};

export default AnimatedNumber;