import React, { useState, useRef } from "react";
import { FaDownload } from "react-icons/fa";
import html2canvas from 'html2canvas';

const DayMenu = ({ meals = {}, onRegenerate }) => {
    const cardRef = useRef(null);
    const [openMeal, setOpenMeal] = useState(null);
    const [isRegenerating, setIsRegenerating] = useState(false);

    const handleDownloadImage = async () => {
        if (cardRef.current === null) {
            return;
        }

        try {
            await new Promise(resolve => setTimeout(resolve, 100));
            const canvas = await html2canvas(cardRef.current, {
                logging: true,
                useCORS: true,
                scale: 2,
                backgroundColor: null
            });

            const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
            const link = document.createElement('a');
            link.download = `menu.png`;
            link.href = image;
            link.click();
        } catch (err) {
            console.error('Oops, something went wrong!', err);
        }
    };

    const mealLabels = ["Breakfast", "Lunch", "Dinner"];

    const toggleMeal = (meal) => {
        setOpenMeal(openMeal === meal ? null : meal);
    };

    const handleRegenerateClick = async () => {
        setIsRegenerating(true);
        await onRegenerate();
        setIsRegenerating(false);
    };

    return (
        <div ref={cardRef} className="p-0 w-full mx-0">
            <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-gray-800 mb-8 uppercase" style={{ fontFamily: 'Poppins, sans-serif' }}>
                    Today's Menu
                </h2>
                <p className="text-sm italic text-gray-600 mb-8">Brought to you by madisonsmenu.com</p>
                <div className="space-y-6">
                    {mealLabels.map((label, index) => (
                        <div key={index} className="text-center">
                            <h3 className="text-xl font-bold text-gray-900" style={{ fontFamily: 'Poppins, sans-serif' }}>{label}</h3>
                            <h4 className="text-lg font-semibold text-gray-800 mt-2" style={{ fontFamily: 'Lato, sans-serif' }}>
                                {meals[label.toLowerCase()]?.title || "No meal available"}
                            </h4>
                            <p className="text-sm text-gray-700 italic" style={{ fontFamily: 'Lato, sans-serif' }}>
                                {meals[label.toLowerCase()]?.description || ""}
                            </p>
                            <button
                                onClick={() => toggleMeal(label)}
                                className="text-sm underline mt-2 focus:outline-none"
                                style={{ color: "#A3B18A" }}
                            >
                                {openMeal === label ? "Hide Recipe & Ingredients" : "View Recipe & Ingredients"}
                            </button>

                            {openMeal === label && (
                                <div className="text-left mt-4 p-2 sm:p-4">
                                    <p className="text-sm text-gray-700 italic">Estimated prep time: {meals[label.toLowerCase()]?.prep_time || "N/A"} minutes</p>
                                    <p className="text-sm text-gray-700 italic">Estimated cook time: {meals[label.toLowerCase()]?.cook_time || "N/A"} minutes</p>
                                    <h5 className="text-md font-semibold text-gray-900 mt-4">Ingredients (4 persons):</h5>
                                    {Array.isArray(meals[label.toLowerCase()]?.ingredients) ? (
                                        <ul className="list-disc list-inside">
                                            {meals[label.toLowerCase()].ingredients.map((ingredient, idx) => (
                                                <li key={idx} className="text-sm text-gray-700">{ingredient}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p className="text-sm text-gray-700">No ingredients available.</p>
                                    )}
                                    <h5 className="text-md font-semibold text-gray-900 mt-4">Recipe:</h5>
                                    {Array.isArray(meals[label.toLowerCase()]?.recipe) ? (
                                        <div className="space-y-2">
                                            {meals[label.toLowerCase()].recipe.map((step, idx) => (
                                                <p key={idx} className="text-sm text-gray-700">{step}</p>
                                            ))}
                                        </div>
                                    ) : (
                                        <p className="text-sm text-gray-700">No recipe available.</p>
                                    )}
                                </div>
                            )}
                            {label === "Dinner" && (
                                <div className="text-center mt-8">
                                    {isRegenerating ? (
                                        <div className="space-y-4">
                                            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 mx-auto"
                                                style={{ borderColor: '#3A5A40' }}>
                                            </div>
                                            <p className="text-gray-700 font-semibold italic" style={{ fontFamily: 'inherit' }}>
                                                Please wait while we regenerate your menu...
                                            </p>
                                        </div>
                                    ) : (
                                        <button
                                            onClick={handleRegenerateClick}
                                            className="hover:text-[#A3B18A] transition duration-200 ease-in-out flex items-center justify-center text-center mx-auto"
                                            style={{ color: "#3A5A40" }}
                                        >
                                            <i className="fas fa-sync-alt mr-2"></i>
                                            Don't like it?
                                        </button>

                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DayMenu;
