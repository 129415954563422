import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function NavBar() {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const links = [
        { name: 'HOME', path: '/' },
        { name: 'ABOUT US', path: '/about' },
        { name: 'MENU', path: '/menu' },
    ];

    return (
        <header className="w-full max-w-7xl mx-auto px-2 py-0 relative">
            <div className="flex items-center justify-between">
                {/* Logo */}
                <Link to="/">
                    <img src="/logo.png" alt="Logo" className="h-12" />
                </Link>
                {/* Hamburger Button for Mobile */}
                <button
                    className="block lg:hidden text-gray-600 ml-auto"
                    onClick={toggleMenu}
                >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                    </svg>
                </button>
                {/* Desktop Menu Items (unchanged) */}
                <ul className="hidden lg:flex space-x-8 font-semibold" style={{ fontFamily: 'Poppins, sans-serif' }}>
                    {links.map(link => (
                        <li key={link.name}>
                            <Link
                                to={link.path}
                                className={`hover:underline ${location.pathname === link.path ? 'underline text-green-700' : ''}`}
                            >
                                {link.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            {/* Improved Mobile Dropdown Menu */}
            {isOpen && (
                <ul className="lg:hidden flex flex-col absolute top-full right-0 bg-white shadow-md rounded-b-lg overflow-hidden w-48 z-10" style={{ fontFamily: 'Poppins, sans-serif' }}>
                    {links.map(link => (
                        <li key={link.name} className="w-full">
                            <Link
                                to={link.path}
                                onClick={() => setIsOpen(false)}
                                className={`block py-3 px-4 text-right hover:bg-gray-100 transition-colors duration-200 ${location.pathname === link.path ? 'bg-gray-200 font-semibold' : ''}`}
                            >
                                {link.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </header>
    );
}

export default NavBar;