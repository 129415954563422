import React, { useState } from 'react';
import supabase from '../supabaseClient';

const Footer = () => {
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');
    const [captchaAnswer, setCaptchaAnswer] = useState('');
    const [captcha] = useState({
        question: 'What is 2 + 2?',
        answer: 4
    });

    const handleEmailSubmit = async (event) => {
        event.preventDefault();

        if (parseInt(captchaAnswer, 10) !== captcha.answer) {
            setStatus('Incorrect CAPTCHA answer. Please try again.');
            return;
        }

        setStatus('Sending...');

        const { error } = await supabase
            .from('feedback')
            .insert([{ email, feedback: 'email' }]);

        if (error) {
            console.error('Error:', error);
            setStatus('Error submitting email');
        } else {
            setStatus('Email submitted successfully!');
            setEmail('');
            setCaptchaAnswer('');
        }
    };

    return (
        <footer className="bg-gray-100 text-gray-800 py-12 text-center w-full">
            {/* Divider line */}
            <div className="w-full flex justify-center">
                <div className="bg-gray-300 h-px w-1/3 mb-8"></div>
            </div>

            {/* About Us section */}
            <div className="text-gray-700 text-center mb-12 mx-auto" style={{ fontFamily: "'Lora', serif" }}>
                <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold mb-2">About Us</h3>
                <p className="text-sm sm:text-base md:text-lg lg:text-xl leading-relaxed">
                    <br />We are building Madison's Menu to become the number one place for gluten-intolerant people.
                    We were sick of having limited options for gluten-free food, so we created this website.
                    The website is named after my beautiful wife, Madison.<br /> <br />
                    Until I was diagnosed with gluten intolerance,
                    life was really hard because we had no idea what was going on, but Madison supported me the whole time. <br /> <br />
                    This website will evolve rapidly to help the community, so please help us by sharing it with your gluten-free friends. <br /> <br />
                    We will add a feedback section in the near future, keep your ideas ready.
                </p>
            </div>

            {/* Rest of your footer content */}
            <div className="mb-4">
                <p className="text-xl font-semibold mb-2" style={{ fontFamily: "'Lora', serif" }}>
                    Get the latest updates. No Spam.
                </p>
                <form onSubmit={handleEmailSubmit} className="flex flex-col justify-center items-center space-y-4">
                    <input
                        type="email"
                        className="p-3 border border-gray-300 rounded-full text-gray-900 w-full max-w-xs sm:max-w-sm"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        style={{ fontFamily: "'Lora', serif" }}
                    />
                    <input
                        type="text"
                        id="captcha"
                        className="p-3 border border-gray-300 rounded-full text-gray-900 w-full max-w-xs sm:max-w-sm"
                        placeholder={captcha.question}
                        value={captchaAnswer}
                        onChange={(e) => setCaptchaAnswer(e.target.value)}
                        required
                        style={{ fontFamily: "'Lora', serif" }}
                    />
                    <button
                        type="submit"
                        className="nature-bg text-white font-bold py-4 px-10 rounded-full shadow-lg hover:bg-[#A3B18A] hover:shadow-xl transition-all duration-300 w-full max-w-xs sm:max-w-sm"
                        style={{ fontFamily: "'Lora', serif" }}
                    >
                        SUBMIT
                    </button>

                </form>
                {status && <p className="mt-2 text-sm text-gray-600">{status}</p>}
            </div>

            {/* Footer note */}
            <p className="text-sm mt-8" style={{ fontFamily: "'Lora', serif" }}>&copy; 2024 Madison's Menu. All rights reserved.</p>
        </footer>
    );
};

export default Footer;
